export default function IconSolidLoading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      id="Loading-Half-Status--Streamline-Micro"
      className="animate-spin"
      height="100%"
      width="100%"
    >
      <desc>Loading Half Status Streamline Icon: https://streamlinehq.com</desc>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.929600000000001 2.8176a5.6000000000000005 5.6000000000000005 0 0 1 7.072 2.6848 1.2000000000000002 1.2000000000000002 0 0 0 2.144 -1.0784A8 8 0 0 0 5.0416 0.5888a8.057599999999999 8.057599999999999 0 0 0 -4.9728 6.384 8.057599999999999 8.057599999999999 0 0 0 3.1408000000000005 7.456 8 8 0 0 0 2.9904 1.3872 1.2000000000000002 1.2000000000000002 0 1 0 0.5408000000000001 -2.3376 5.6000000000000005 5.6000000000000005 0 0 1 -2.0944 -0.9712000000000001A5.6576 5.6576 0 0 1 2.4480000000000004 7.2864a5.6576 5.6576 0 0 1 3.4816000000000003 -4.468800000000001Zm10.068800000000001 5.3984000000000005a1.2000000000000002 1.2000000000000002 0 0 0 -2.4000000000000004 -0.0576c-0.0064 0.26720000000000005 -0.032 0.528 -0.0736 0.784a1.2000000000000002 1.2000000000000002 0 0 0 2.368 0.392 8.032 8.032 0 0 0 0.10560000000000001 -1.1184Zm-1.4720000000000002 4.432a1.2000000000000002 1.2000000000000002 0 1 0 -1.9568000000000003 -1.3904c-0.144 0.20640000000000003 -0.30400000000000005 0.4 -0.4752 0.584a1.2000000000000002 1.2000000000000002 0 1 0 1.7552 1.6384 8.032 8.032 0 0 0 0.6784 -0.8320000000000001ZM10.9216 15.472000000000001a1.2000000000000002 1.2000000000000002 0 1 0 -0.8768000000000001 -2.2352000000000003 5.5472 5.5472 0 0 1 -0.7280000000000001 0.2288 1.2000000000000002 1.2000000000000002 0 0 0 0.5616 2.3344c0.35840000000000005 -0.0864 0.7056 -0.1968 1.0432000000000001 -0.328Z"
        clipRule="evenodd"
        strokeWidth="1"
      ></path>
    </svg>
  );
}
